import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { Toast } from 'vant';
import { storage } from '@/utils/storage';
import { isEmpty } from 'lodash-es';
import { requestParameters } from '@/utils/config';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import CryptoJS from 'crypto-js';
import { SIGNKEY } from './axiosKeyConfig';
import router from '@/router';
import { useUserStore } from '@/store/modules/user';
import { isPhone } from '@/utils/common';
import { log } from 'console';

axios.defaults.timeout = 1000 * 60;
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';
// 创建axios实例
const service = axios.create({
  // 根据不同env设置不同的baseURL
  baseURL: '',
});

// axios实例拦截请求
const timestamps = Date.now();

function createRequestSign(data: any, timestamps?: number | string, method?: string): string {
  const keyList = Object.keys(data);
  let urlParams = '';
  if (method === 'get') {
    for (const key of keyList) {
      urlParams +=
        keyList[keyList.length - 1] !== key
          ? `${key}=${encodeURI(<string>data[key])}&`
          : `${key}=${encodeURI(<string>data[key])}`;
    }
  } else {
    urlParams = JSON.stringify(data);
  }
  urlParams += `${timestamps}${SIGNKEY}`;
  // console.log('--------urlParams-->', urlParams);
  return urlParams;
}

// axios实例拦截请求
service.interceptors.request.use(
  // enc加密
  (config: any) => {
    // 获取后台的动态接口域名
    const { axiosBaseURL } = useUserStore();
    config.baseURL = axiosBaseURL;

    const token = storage.getItem<{ token: string }>('userLoginInfo', 1)?.token || '';
    let str;
    if (!isEmpty(config.data) || !isEmpty(config.params)) {
      const data = config.data || config.params;
      str = createRequestSign(data, timestamps, config.method);
    } else {
      str = `${timestamps}${SIGNKEY}`;
      config.data = undefined;
    }

    config.headers = {
      ...config.headers,
      token: token,
      sign: CryptoJS.MD5(str).toString().toLowerCase(),
      timestamps: timestamps.toString(),
      os: 'h5',
      osType: 'h5',
      businessType: 'crm',
    };

    if (config.method === 'get') config.headers['Content-Type'] = '';
    return config;
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  }
);

// axios实例拦截响应
service.interceptors.response.use(
  // 2xx时触发
  (response: AxiosResponse) => {
    // response.data就是后端返回的数据，结构根据你们的约定来定义
    // 响应解密
    if (response?.config?.responseType == 'blob') {
      return response;
    } else {
      const resData: any = response.data;

      // console.log(resData, '----响应数据', response);

      const { code, message }: { code: number; message: string } = resData;
      if (code !== requestParameters.request_SUCCESS) {
        switch (code) {
          case requestParameters.request_FAIL:
          case requestParameters.request_SIGN_ERROR:
          case requestParameters.request_AUTH_ERROR:
          case requestParameters.request_RUN_EXCEPTION:
          case requestParameters.request_BLACK:
          case requestParameters.request_NOT_PERMISSION:
          case requestParameters.request_TIMEOUT:
          case requestParameters.request_VISIT_OUT:
          case requestParameters.request_NOT_FOUND:
            Toast(message);
            break;
          case requestParameters.request_TOKEN_ERROR:
            Toast('登录已失效，请重新登录!');
            localStorage.clear();
            sessionStorage.clear();
            router.replace('/login');
            break;
        }
        return Promise.reject(resData);
      } else {
        return resData;
      }
    }
  },
  // 非2xx时触发
  (error: AxiosError) => {
    Toast('网络错误');
    return Promise.reject(error);
  }
);

export type { AxiosResponse, AxiosRequestConfig };

export default service;
