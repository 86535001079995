let SIGNKEY = '';
let ossPath = '';
switch (import.meta.env.VITE_USER_NODE_ENV) {
  case 'pro':
    ossPath = 'https://hxqb-pro.oss-cn-chengdu.aliyuncs.com/open/domain/'; // 阿里云动态域名，不用修改
    SIGNKEY = 'pk1ZD4sbbCfniFQzWiYRRNddt7h3fQyk';
    break;
  case 'test':
    ossPath = 'https://hxqb-test.oss-cn-chengdu.aliyuncs.com/open/domain/'; // 阿里云动态域名，不用修改
    SIGNKEY = 'pk1ZD4sbbCfniFQzWiYRRNddt7h3fQyk';
    break;
  default:
    ossPath = 'https://hxqb-pro.oss-cn-chengdu.aliyuncs.com/open/domain/'; // 阿里云动态域名，不用修改
    SIGNKEY = 'pk1ZD4sbbCfniFQzWiYRRNddt7h3fQyk';
}
export { SIGNKEY, ossPath };
