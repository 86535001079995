import { defineStore } from 'pinia';

export const useUserStore = defineStore('user', () => {
  const axiosBaseURL = ref('');
  function setAxiosBaseURL(url: string) {
    axiosBaseURL.value = url;
  }

  const imgBaseURL = ref('');
  function setImgBaseURL(url: string) {
    imgBaseURL.value = url;
  }

  const routeAuth = ref<string[]>([]);
  function setRouteAuth(data: any) {
    routeAuth.value = data;
  }
  return {
    axiosBaseURL,
    setAxiosBaseURL,
    routeAuth,
    setRouteAuth,
    imgBaseURL,
    setImgBaseURL,
  };
});
