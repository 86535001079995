import type { Directive, DirectiveBinding } from 'vue';
import { useUserStore } from '@/store/modules/user';

export const permission: Directive = {
  mounted(el: HTMLElement, binding: DirectiveBinding) {
    const { value } = binding;
    if (value) {
      const { routeAuth }: { routeAuth: string[] } = useUserStore();
      const has = routeAuth.some((item) => item === value);
      if (!has) el.parentNode && el.parentNode.removeChild(el);
    }
  },
};
export default permission;
