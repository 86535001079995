import baseImg from '@/assets/img/logo.jpg';
import { useUserStore } from '@/store/modules/user';
import { getRouteAuth_api } from '@/api/auths';
export const baseImgUrl = {
  jointUrl(url: any) {
    const { imgBaseURL } = useUserStore();
    if (url == undefined || url == '' || url == null || url == 'null' || url == 'undefined') {
      return baseImg;
    } else if (url.indexOf('http') > -1) {
      return url;
    } else {
      return imgBaseURL + url;
    }
  },
};

export const riFormType = {
  addressSelect: 'riAddressSelect',
  input: 'riInput',
  select: 'riSelect',
};

export const requestParameters = {
  // 请求参数
  request_SUCCESS: 0, // 请求成功状态
  request_FAIL: 1, // 请求失败状态
  request_TOKEN_ERROR: 401, // 登录失效
  request_SIGN_ERROR: 402, // 签名校验失败
  request_AUTH_ERROR: 403, // 访问受限
  request_RUN_EXCEPTION: -1, // 主动抛出异常（一般为参数校验失败，或流程校验不通过等）
  request_BLACK: -10, // 黑名单
  request_NOT_PERMISSION: -20, // 无权限访问
  request_TIMEOUT: -30, // 请求超时
  request_VISIT_OUT: -40, // 访问次数受限
  request_NOT_FOUND: 404, // 资源不存在
};

export const getRouteAuth = async (path: string) => {
  const { setRouteAuth } = useUserStore();
  const name = path.replace(/\//g, '');
  if (name) {
    const data = await getRouteAuth_api({ name });
    setRouteAuth(data);
  }
};
